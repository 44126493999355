import React, { useEffect, useState } from 'react';

const Pricing = () => {
  const [plans, setPlans] = useState([]);
  const [allFeatures, setAllFeatures] = useState([]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await fetch('https://wbtechindia.com/apis/masterportallin/plan/upgrade/Active', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ SoftwareID: 11 }),
        });
        const data = await response.json();
        
        if (data.status === 200 && data.data) {
          setPlans(data.data);

          // Extract all unique features across plans
          const uniqueFeatures = new Set();
          data.data.forEach(plan => {
            plan.AllFeatures.forEach(feature => {
              uniqueFeatures.add(feature.FeatureName);
            });
          });
          setAllFeatures([...uniqueFeatures]); // Convert Set to array
        }
      } catch (error) {
        console.error('Error fetching plans:', error);
      }
    };

    fetchPlans();
  }, []);

  return (
    <div className="text-white bg-primary-bg pricing-table scroll-mt-[100px] px-10 py-10 " id="pricing" >
          <h2 className="text-xl text-white leading-10 text-center font-bold px-10 pb-2 Pt-10 ">Plans & Pricing</h2>
      <h4 className="text-lg font-bold text-text-secondary text-center py-6 px-4">
        Select the plan that best fits your organization's needs
      </h4>
      
      <div className="table-wrapper overflow-x-auto">
        <table className="table-auto w-full border-collapse">
          <thead>
            <tr>
              <th className="border px-4 py-2 text-center">Features</th>
              {plans.map((plan) => (
                <th className="border px-4 py-2 text-center" key={plan._id}>
                  <div>{plan.Name}</div>
                  <div className="text-xs pt-2">₹{plan.Amount}/ {plan.Duration} Days</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {allFeatures.map((featureName) => (
              <tr key={featureName}>
                <td className="border px-4 py-2 text-center">{featureName}</td>
                {plans.map((plan) => {
                  const currentFeature = plan.AllFeatures.find(f => f.FeatureName === featureName);
                  return (
                    <td className="border px-4 py-2 text-center" key={plan._id}>
                      {currentFeature 
                        ? (currentFeature.Count ? currentFeature.Count : '✔️') 
                        : '❌'}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Pricing;
