// import React from 'react';
// import mwbLogo from '../../assets/mwblogo.png';
// const Footer = () => {
//     const date = new Date();
//     const year = date.getFullYear();
    
//     return (
//         <div className='w-full flex justify-center   items-center  bg-gray-950 text-white text-center py-4 mt-4 px-4'>
//         <div className='flex items-center justify-center mb-2'>
//             <img src={mwbLogo} alt='MWB Technologies Logo' className='w-20 h-auto' />
//         </div>
//         <p class>© {year}. MWB Technologies India Pvt Ltd. All Rights Reserved</p>
//     </div>
//     );
// }

// export default Footer;
import React from "react";
import mwb from "../../assets/mwblogo.png";
import logo from '../../assets/footerlogo.png';
import { IoMail } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const nav = useNavigate();

  return (
    <div className="w-full bg-primary-bg text-black grid grid-cols-2  text-white max-sm:grid-cols-1 text-center justify-between p-4 py-10 gap-10 ">
      <div className="flex flex-col items-center justify-center">
      <p className=" font-medium text-xs leading-8 max-w-[350px]">
          CTS 122/141A, W B Plaza, New Cotton Market, Opp Traffic Police Station, Hubli, Dharwad, Karnataka, 580029
        </p>
        <p className="mt-4 font-medium text-xs flex gap-2 items-center hover:underline cursor-pointer">
          <IoMail className="w-4 h-4" />
          <a href="mailto:reachus@wbtechindia.com">reachus@wbtechindia.com</a>
        </p>
      </div>
      <div className="flex flex-col items-center  text-sm gap-2">
        <div className="w-full flex justify-center divide-x-2 my-4">
          <div className="w-12 pr-2">
            <img src={logo} alt="logo" className="w-12 " />
          </div>
          <div className="w-12 pl-2">
            <img src={mwb} alt="mwb" className="w-12 " />
          </div>
        </div>
        <p className="font-medium text-xs leading-8 ">
          An app by{" "}
          <a
            href="https://wbtechindia.com/#/"
            className="hover:underline text-teal-600"
            target="_blank"
          >
            MWB Technologies India Pvt Ltd.
          </a>
        </p>
        <p className=" font-medium text-xs leading-6 "> © {year} MWB Technologies India Pvt Ltd. All rights reserved. </p>
        <div className="flex justify-center items-center gap-2 ">
        <a
          onClick={() => {
            nav("/privacy");
          }}
          className=" text-xs font-medium underline cursor-pointer"
        >
          Privacy Policy
        </a>
        <a
          onClick={() => {
            nav("/terms");
          }}
          className="text-xs underline cursor-pointer"
        >
          Terms and Conditions 
        </a>

        </div>
       

      </div>
    </div>
  );
};

export default Footer;
