

import React from "react";
import "./StockSchedule.css";
import stockschedule from "../../assets/stockschedule.png"; // Replace with the actual image path

const StockSchedule = () => {
  const ScheculeItems = [
    {
      id: 1,
      icon: "01",

      description:
        "Effortlessly schedule stock processes for each warehouse - daily, weekly, monthly, or yearly",
    },
    {
      id: 2,
      icon: "02",

      description:
        "Customers can choose specific days for stock management, ensuring convenience and tailored operations",
    },
    {
      id: 3,
      icon: "03",

      description:
        "Scheduling stock processes in advance helps businesses optimize inventory management and streamline operations",
    },
    {
      id: 4,
      icon: "04",

      description:
        "Automating the stocking schedule reduces manual effort and oversight, enabling teams to focus on critical tasks",
    },
  ];
  return (
   
    <div className="bg-secondary-bg text-white p-10">
      <h2 className="text-xl text-black leading-10 text-center font-bold px-10 pb-2 ">
      Flexible Stock Scheduling
      </h2>
      <div className="flex flex-col md:flex-row items-center text-black">
        <div className="md:w-3/4 md:pl-10 mt-6 md:mt-0">
        <div className="support-row">
            {ScheculeItems.slice(0, 2).map((item) => (
              <div className="support-item  mr-10 my-4" key={item.id}>
                <div className="bg-primary-bg text-white  mt-4  stockicon">{item.icon}</div>
                <div className="text">
                  {/* <h3>{item.title}</h3> */}
                  <p className="text-xs ml-2 leading-6 sm:leading-10">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="support-row" >
            {ScheculeItems.slice(2, 4).map((item) => (
              <div className="support-item mr-10 my-4" key={item.id}>
                <div className="bg-primary-bg text-white mt-4 stockicon">{item.icon}</div>
                <div className="text">
                  {/* <h3>{item.title}</h3> */}
                  <p className="text-xs ml-2 leading-6 sm:leading-10">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="md:w-1/2">
          <img src={stockschedule} alt="Support" className="w-full h-auto" />
        </div>
      </div>
    </div>
  );
};

export default StockSchedule;
