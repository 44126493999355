import React from "react";
import stockentry from "../../assets/stockentry.png"; // Replace with the actual image path

const StockEntry = () => {
  const cardData = [
    {
      id: 1,
      // title: 'Powerful Mobile and Online App',
      content: 'Supervisors easily access a pre loaded list of opening stock items once uploaded by the admin',
    },
    {
      id: 2,
      // title: 'Brings More Transparency and Speed',
      content: 'Minimal technical knowledge is needed, making it accessible for all supervisors',
    },
    {
      id: 3,
      // title: 'Special for Multiple Use Capabilities',
      content: 'Sync all entries effortlessly with a single click after adding items',
    },
    {
      id: 4,
      // title: 'Special for Multiple Use Capabilities',
      content: 'The streamlined process reduces stock entry time, making it stress-free for employees',
    },
  ];
  return (
    <div className="bg-primary-bg text-white p-10">
          <h2 className="text-xl text-white leading-10 text-center font-bold px-10 pb-10 ">Effortless Physical Stock Entry</h2>
      <div className="flex flex-col md:flex-row items-center">
        {/* Left Image */}
        <div className="md:w-1/2">
          <img src={stockentry} alt="Phone" className="w-full h-auto" />
        </div>

        {/* Right Cards */}
        <div className="md:w-3/4 md:pl-10 mt-6 md:mt-0">
        <div className="space-y-6 ">
            {cardData.map((card) => (
              <div
                key={card.id}
                id={`card-${card.id}`} 
                className="bg-primary-bg border border-white px-4 py-2 rounded-lg flex items-start"
              >
           
                <div className="">
             
                  <p className="text-xs  leading-6 sm:leading-10 pr-6 ">{card.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockEntry;