import React from 'react';
import './StockManagement.css';
import withoutinternet from "../../assets/withoutinternet.png"
import withinternet from "../../assets/withinternet.png"
const StockManagement = () => {
  return (
    <div className="bg-secondary-bg pt-10">
        <h2 className="manage-title text-xl text-black leading-10 text-center font-bold px-10 pb-10 ">Offline Stock Management</h2>
        <div className="card-container bg-primary-bg py-10">
        
        <div className="card mb-2">
            <div className="card-icon flex justify-center">
                <img src={withoutinternet} alt="" />
            </div>
            
            <p className="text-xs  leading-6 sm:leading-10 pr-6 ">
            Supervisors can also enter stock without internet, ensuring productivity in the warehouse
            </p>
        </div>
        <div className="card mb-2">
            <div className="card-icon flex justify-center">
                <img src={withinternet} alt=" " />
            </div>
          
            <p className="text-xs  leading-6 sm:leading-10 pr-6 ">
            Once internet is restored, locally stored data syncs seamlessly, keeping records up to date
            </p>
        </div>
    </div>
    </div>
    
  );
};

export default StockManagement;