
// import React, { useRef } from "react";
// import ezstocksLogo from "../../assets/ezstockslogo.png"; // Ensure the path is correct

// const Hero2 = () => {
//   const featuresRef = useRef(null);

//   const scrollToFeatures = () => {
//     if (featuresRef.current) {
//       featuresRef.current.scrollIntoView({ behavior: "smooth" });
//     }
//   };

//   return (
//     <div className="container1 home bg-custom-bg rounded-2xl" id="home">
//       <section className="text-custom-black">
//       <div className="mx-auto max-w-screen-xl px-4 py-20 lg:py-20 lg:flex lg:h-screen lg:items-center">

//           <div className="flex flex-col lg:flex-row w-full">
//             {/* Left section with image */}
//             <div className="lg:w-2/4 w-3/4 md:w-2/4 mx-auto flex justify-center items-center lg:-mt-16">
//   <img
//     src={ezstocksLogo}
//     alt="Easy Stocks Logo"
//     className="w-full h-auto max-w-md lg:max-w-lg"
//   />
// </div>
//             {/* Right section with content */}
//             <div className="lg:w-3/5 text-center lg:text-left md:mt-10 lg:mt-30 flex flex-col justify-center lg:justify-start">
//               <p className="mx-auto max-w-xl sm:text-xl text-custom-gray"></p>
//               <p className="p-1   bg-gradient-to-r   from-slate-600 via-slate-400 to-slate-800 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl leading-tight mt-0 mb-4 float">
//                 Smarter Tracking Faster <span className="text-blue-900">Growth</span>
//               </p>
//               <div className="mt-12 md:mt-0 flex flex-wrap justify-center lg:justify-start gap-4">
//                 <a
//                   className="block w-full rounded  bg-custom-black px-12 py-3 text-sm font-medium text-white  hover:border-2 hover:bg-white hover:text-custom-black focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
//                   href="#"
//                   onClick={scrollToFeatures}
//                 >
//                   Get Started
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Features Section */}
//       <div ref={featuresRef} className="features">
//         {/* Your features content */}
//       </div>
//     </div>
//   );
// };

// export default Hero2;
import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import slider1 from "../../assets/banner/slider1.png";
import slider2 from "../../assets/banner/slider2.jpg";
import slider3 from "../../assets/banner/slider3.png";
import slider4 from "../../assets/banner/slider4.png";
import slider5 from "../../assets/banner/slider5.png";
import slider6 from "../../assets/banner/slider6.png";

import { useEffect } from "react";

const Hero2 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(true); // State to control transition
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const globalVideoUrl = intruductionvideo;

  const slides = [
    {
      id: 1,
      title: "Welcome to Easy Stock  ",
      subtitle: (
        <>
     Simplify warehouse management with <br></br>fast, accurate, hassle-free stock-taking!
        </>
      ),
      bgImage: slider2,
      
  
    },
    {
      id: 2,
      
      title: "Seamless Tally Sync  ",
      subtitle: (
        <>
        
        Effortlessly upload Tally masters <br></br>warehouses, items, and stock data!
        </>
      ),
      bgImage: slider1,
     
      
    },
    {
      id: 3,
      title: "User-Friendly Entry",
      subtitle: (
        <>
        Easily take stock entries<br></br> and sync data with one click!
        </>
      ),
      bgImage: slider3,
    
    },
    {
      id: 4,
      title: "Effortless Verification",
      subtitle: (
        <>
         Stress-free stock verification <br></br>by warehouse and item!
        </>
      ),
      bgImage: slider4,
   
    },
    {
      id: 5,
      title: "Quick Stock Match",
      subtitle: (
        <>
          Quickly compare Tally closing and <br></br> supervisor entries for mismatches!
     
        </>
      ),
      bgImage: slider5,
     
    },
    {
      id: 6,
      title: "Instant Reports",
      subtitle: (
        <>
     Make smart decisions with <br></br>real-time inventory insights!
        
        </>
      ),
      bgImage: slider6,
     
    }
  ];

  const nextSlide = () => {
    if (currentIndex === slides.length - 1) {
      // Disable transition, instantly jump to first slide
      setIsTransitioning(false);
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex === 0) {
      // Disable transition, instantly jump to last slide
      setIsTransitioning(false);
      setCurrentIndex(slides.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Re-enable transition after jump
  useEffect(() => {
    if (!isTransitioning) {
      const timer = setTimeout(() => {
        setIsTransitioning(true);
      }, 50); // Small delay to avoid visual issues
      return () => clearTimeout(timer);
    }
  }, [isTransitioning]);


  return (
    <>
   <div className="relative w-full h-full sm:h-full mb-0"  id="home"> 
        <div className="overflow-hidden h-full w-full">
          <div
            className={`flex ${
              isTransitioning ? "transition-transform duration-500" : ""
            }`}
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                className="flex-shrink-0 w-full h-full bg-cover bg-no-repeat bg-center flex items-start justify-start"
                style={{ backgroundImage: `url(${slide.bgImage})` }}
              >
                <div className="sliderimage bg-opacity-10 text-primary items-start justify-start bg-center sm:px-20 sm:py-10 sm:mx-2 sm:my-20 px-4 py-2 mx-2 my-2">
                  <div className="text-3xl font-bold leading-12 mx-2 sm:mx-0 sm:my-10 mt-10 ">
                    {slide.title}
                  </div>
                  <div className="text-2xl font-bold leading-10 mx-4 sm:mx-0 my-2 pb-4 ">
                    {slide.subtitle}
                  </div>

             
                </div>
              </div>
            ))}
          </div>
        </div>

        <button
          onClick={prevSlide}
          className="sm:ml-10 ml-2 absolute left-0 top-1/2 transform -translate-y-1/2 p-2 bg-white rounded-full shadow-lg"
        >
          <FaChevronLeft />
        </button>
        <button
          onClick={nextSlide}
          className="sm:mr-10 mr-2 absolute right-0 top-1/2 transform -translate-y-1/2 p-2 bg-white rounded-full shadow-lg"
        >
          <FaChevronRight />
        </button>
      </div>

     
    </>
  );
};

export default Hero2;
