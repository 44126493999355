import React from 'react';
import stockverification from "../../assets/stockverification.png";
const StockVerification = () => {
  const verificationcard = [
    {
      id: 1,
      icon: "01", 
    
      description: "Admins can easily verify stock entered by supervisors across all warehouses for accuracy",
      iconColor: "bg-white",
    },
    {
      id: 2,
      icon: "02",
 
      description: "The app alerts admins to discrepancies between physical stock and Tally entries",
      iconColor: "bg-white",
    },
    {
      id: 3,
      icon: "03",
    
      description: "Quickly identify errors without searching, simplifying verification",
      iconColor: "bg-white",
    },
    {
      id: 4,
      icon: "04",
    
      description: "Ensure accuracy in inventory management",
      iconColor: "bg-white",
    },
  ];

  return (
    <div className="bg-secondary-bg text-black pt-10 px-10">
      <h2 className=" text-xl text-black leading-10 text-center font-bold px-10  "> 
        Streamlined Stock Verification
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-6 mt-20">
          <img src={stockverification} alt="Support" className="w-full h-auto" />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 py-10">
          {verificationcard.map((card) => (
            <div
              key={card.id}
              className={` bg-primary-bg text-white shadow-lg p-6 rounded-lg 
                 ${card.id === 1 ? 'mt-12 pb-0' : ''}
          ${card.id === 2 ? 'mt-0 pb-10' : ''}
          ${card.id === 3 ? 'mt-6 pb-10 ' : ''}
          ${card.id === 4 ? 'mb-12 pb-0 pt-10' : ''}`}
            >
              <div
                className={`w-12 h-12 ${card.iconColor} flex items-center justify-center rounded-full text-black text-2xl mb-4`}
              >
                {card.icon}
              </div>
              {/* <h3 className="text-lg font-semibold mb-2">{card.title}</h3> */}
              <p className="text-xs ml-2 leading-6 sm:leading-10">
                {card.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StockVerification;
