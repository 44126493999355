import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Hero2 from "./components/Hero2/Hero2";
import Pricing from "./components/Pricing/Pricing";
import Contact from "./components/Contact/Contact";
import DownloadNow from "./components/DownloadNow/DownloadNow";
import EasyStockFeatures from "./components/Features2/EasyStockFeatures";
import MoreApps from "./components/MoreApps/MoreApps";
import Intruduction from "./components/intruduction/intruduction";
import StockSchedule from "./components/StockSchedule/StockSchedule"
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy"; 
import StockEntry from "./components/StockEntry/StockEntry";
import StockManagement from "./components/StockManagement/StockManagement";
import StockMismatch from "./components/StockMismatch/StockMismatch";
import StockVerification from "./components/StockVerification/StockVerification";
import Reports from "./components/Reports/Reports";
import Partners from "./components/Partners/Partners";
import TermsAndConditions from "./components/PrivacyPolicy/TermsAndConditions";

function App() {
  return (
    <Router basename="/">
      <Layout>
        <Routes>
          <Route path="/" element={
            <>
              <Hero2 />
              <div className="container-traditional">
                <Intruduction/>
                <StockSchedule/>
                <StockEntry/>
                <StockManagement/>
                <StockVerification/>
                <StockMismatch/>

           <Reports/>
                {/* <EasyStockFeatures /> */}
                {/* <DownloadNow /> */}
                <Pricing />
                <Partners/>
                <Contact />
                <MoreApps />
              </div>
            </>
          } />
          <Route path="/privacy" element={<PrivacyPolicy />} />  {/* Define the route for PrivacyPolicy */}
          <Route path="/terms" element={<TermsAndConditions />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
