// Partner.js
import React, { useState, useEffect } from "react";
import "./Partners.css";
import { useForm, ValidationError } from "@formspree/react";
import partners from "../../assets/partners.png"

const Partner = () => {
  const [state, handleSubmit] = useForm("mdovkgeq");
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Trigger a page refresh 5 seconds after successful submission
  useEffect(() => {
    if (state.succeeded) {
      const timer = setTimeout(() => {
        window.location.reload(); // Refresh the page after 5 seconds
      }, 3000);

      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
    }
  }, [state.succeeded]);

  return (
    <div className="  scroll-mt-[100px]  bg-secondary-bg px-10 py-4 " id="partners">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
      <div className=" partner-container mt-4 mb-4 scroll-mt-[100px]" id="partners" >
      <div className="partner-content text-xl font-bold my-10 leading-12 text-center text-black">
        {/* <h4 className="px-10 partner-title text-xl">
          Empowering Success Together:
        </h4> */}
        <h5 className="px-10 leading-2 partner-title text-xl mt-10 mb-6 text-customPurple sm:whitespace-nowrap sm:ml-10">
          Join the Easy Stock Channel <br></br><br></br> Partner Network!
        </h5>
        <button
          className="partner-button bg-primary-bg text-white text-xs py-4 px-4"
          onClick={handleOpenModal}
        >
          Apply Now
        </button>
      </div>
      <div className="partner-image">
        <img
          src={partners}
          alt="Partnership"
         
        />
      </div>

      {/* Modal for the form */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50 w-100">
          <div className="bg-white p-10 rounded-lg shadow-lg relative w-[400px]">
            <button
              className="absolute top-2 right-2 text-gray-600"
              onClick={handleCloseModal}
            >
              X
            </button>
            <h2 className="text-md font-bold mb-10 text-primary">
              Become a Partner
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="relative mb-6">
                <input
                  type="text"
                  id="name"
                  name="name"
                  pattern="^[A-Za-z]+( [A-Za-z]+)*$"
                  required
                  className="block w-full p-2 border-b-2 border-gray-300 focus:outline-none focus:border-blue-500"
                />
                <label
                  htmlFor="name"
                  className="text-xs absolute left-2 -top-2.5 text-gray-500 transition-all duration-200 transform scale-75 origin-top-left"
                >
                  Name
                </label>
              </div>
              <div className="relative mb-6">
                <input
                  type="tel"
                  id="mobile"
                  name="mobile"
                  pattern="^[6-9][0-9]{9}$"
                  required
                  className="block w-full p-2 border-b-2 border-gray-300 focus:outline-none focus:border-blue-500"
                />
                <label
                  htmlFor="mobile"
                  className="text-xs absolute left-2 -top-2.5 text-gray-500 transition-all duration-200 transform scale-75 origin-top-left"
                >
                  Mobile
                </label>
              </div>
              <div className="relative mb-6">
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  className="block w-full p-2 border-b-2 border-gray-300 focus:outline-none focus:border-blue-500"
                />
                <label
                  htmlFor="email"
                  className="text-xs absolute left-2 -top-2.5 text-gray-500 transition-all duration-200 transform scale-75 origin-top-left"
                >
                  Email
                </label>
              </div>
              <div className="relative mb-6">
                <input
                  type="text"
                  id="place"
                  name="place"
                  pattern="^[A-Za-z]+( [A-Za-z]+)*$"
                  required
                  className="block w-full p-2 border-b-2 border-gray-300 focus:outline-none focus:border-blue-500"
                />
                <label
                  htmlFor="place"
                  className="text-xs absolute left-2 -top-2.5 text-gray-500 transition-all duration-200 transform scale-75 origin-top-left"
                >
                  Place
                </label>
              </div>
              <div className="relative mb-6">
                <textarea
                  id="description"
                  name="description"
                  required
                  className="block w-full p-2 border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 min-h-[50px]"
                />
                <label
                  htmlFor="description"
                  className="text-xs absolute left-2 -top-2.5 text-gray-500 transition-all duration-200 transform scale-75 origin-top-left"
                >
                  Description
                </label>
              </div>
              <button
                type="submit"
                disabled={state.submitting}
                className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600"
              >
                Submit
              </button>

              {/* Thank you message after submission */}
              {state.succeeded && (
                <p className="text-green-600 mt-4 text-xs leading-6">
                 Your enquiry has been submitted successfully. Our executive will get back to you shortly
                </p>
              )}
            </form>
          </div>
        </div>
      )}
    </div>
   </div>
   </div>
  );
};

export default Partner;
