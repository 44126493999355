import React from "react";
import Stockmismatch from "../../assets/Stockmismatch.png";

const StockMismatch = () => {
  const mismatch = [
    {
      id: 1,
      
      description: "Resolve Stock Mismatches Instantly and Accurately!",
      icon: "01",
    },
    {
      id: 2,
      // title: "Targeting & Positioning",
      description: "Automatically compare physical stock with Tally entries",
      icon: "02",
    },
    {
      id: 3,
    // title: "Targeting & Positioning",
    description: "Identify under- or over-stock issues in seconds with one click",
    icon: "03",
    },
    {
      id: 4,
   // title: "Targeting & Positioning",
   description: "Easily edit and update mismatched stock entries for accuracy",
   icon: "04",
    },
  ];

  return (
    <div className="bg-primary-bg text-white p-10">
     <h2 className="text-xl text-white leading-10 text-center font-bold px-10 pb-10 ">Quick Stock Mismatch Resolution</h2>
  

      <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
        {/* Left Column */}
        <div className="space-y-6  ">
          {mismatch.slice(0, 2).map((service) => (
            <div key={service.id} className="border border-gray-500  px-4 py-4 rounded-lg">
              <div className="flex items-center mb-4 ">
              <span className="text-sm mr-4 flex items-center bg-secondary-bg  px-4 py-2 border rounded-full border-gray-300 text-black">{service.icon}</span>
                <h3 className="text-sm font-semibold">{service.title}</h3>
              </div>
              <p className="text-xs ml-2 leading-6 sm:leading-10">{service.description}</p>
            </div>
          ))}
        </div>

        {/* Middle Image */}
        <div className="flex justify-center w-full">
          <img
            src={Stockmismatch}
            alt="Consulting"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Right Column */}
        <div className="space-y-6 ">
          {mismatch.slice(2, 4).map((service) => (
            <div key={service.id} className="border border-white px-4 py-4 rounded-lg">
              <div className="flex items-center mb-4">
              <span className="text-sm mr-4 flex items-center bg-secondary-bg px-4 py-2 border rounded-full border-gray-300 text-black">{service.icon}</span>
                <h3 className="text-sm font-semibold">{service.title}</h3>
              </div>
              <p className="text-xs ml-2 leading-6 sm:leading-10">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StockMismatch;