import React from "react";
import stockreports from "../../assets/stockreports.png"; // Replace with the actual image path

const Reports = () => {
  const cardData = [
    {
      id: 1,
      // title: 'Powerful Mobile and Online App',
      content: 'Mismatch Items Report: Quickly spot items with stock discrepancies',
    },
    {
      id: 2,
      // title: 'Brings More Transparency and Speed',
      content: 'Short/Excess Items Report: Get insights on under stocked or overstocked items for timely action',
    },
    {
      id: 3,
      // title: 'Special for Multiple Use Capabilities',
      content: 'Master Items Report: Access detailed data on all master items for accurate tracking',
    },
    {
      id: 4,
      // title: 'Special for Multiple Use Capabilities',
      content: 'Consolidated & Supervisor Reports: Review mismatch and supervisor breakdowns for accountability',
    },
  ];
  return (
    <div className="bg-secondary-bg text-white p-6">
          <h2 className="text-xl text-black leading-10 text-center font-bold px-10 pb-10 ">Comprehensive Stock Reports</h2>
      <div className="flex flex-col md:flex-row items-center">
        {/* Left Image */}
        <div className="md:w-1/2">
          <img src={stockreports} alt="Phone" className="w-full h-full" />
        </div>

        {/* Right Cards */}
        <div className="md:w-3/4 md:pl-10 mt-6 md:mt-0">
        <div className="space-y-6 ">
            {cardData.map((card) => (
              <div
                key={card.id}
                id={`card-${card.id}`} 
                className="bg-primary-bg px-4 py-2 rounded-lg flex items-start"
              >
           
                <div className="">
             
                  <p className="text-xs leading-6 sm:leading-10 pr-6 ">{card.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;