import React from "react";
import introimage from "../../assets/intruduction.png";

const intruduction = () => {
  return (

    
    <div className="bg-primary-bg text-white p-10 scroll-mt-[100px]" id="features"> 
         <h2 className="text-xl text-white leading-10 text-center font-bold px-10 pb-10 ">
            Easy Stock Simplifies Inventory Management
          </h2>
      <div className="flex flex-col md:flex-row items-center">
        {/* Left Image */}
        <div className="md:w-1/3">
          <img src={introimage} alt="Easy Stock" className="w-full h-auto" />
        </div>

        {/* Right Text Content */}
        <div className="md:w-3/4 md:pl-10 mt-6 md:mt-0">
       
        <ul className="space-y-4">
        {/* First item */}
        <li className="flex items-start">
        <span className="w-6 h-6 flex justify-center items-center bg-green-500 text-white rounded-full mr-2 mt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
          <p className="text-xs leading-6 sm:leading-10 pr-10">
            Easy Stock allows businesses to efficiently track physical stock and
            Tally entries across multiple warehouses.
          </p>
        </li>
        

        <li className="flex items-start">
          <span className="w-10 h-6 flex justify-center items-center bg-green-500 text-white rounded-full mr-2 mt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
          <p className="text-xs leading-6 sm:leading-10 pr-10">
            With its user-friendly interface and real-time data synchronization,
            Easy Stock ensures accurate reporting and streamlined operations for
            effective inventory control.
          </p>
        </li>
      </ul>
        </div>
      </div>
    </div>
  );
};

export default intruduction;
